import React from "react";
import { graphql } from "gatsby";
import Page from "~/templates/Page";

function PressRoom({ data: { template } }) {
  return (
    <Page
      data={{
        page: {
          metadata: {
            title: "Press room",
            description: {
              internal: {
                content: "view recent PT Blink press releases",
              },
            },
            image: null,
          },
          sections: [
            ...template.sections,
            {
              __typename: "ContentfulAllPressReleasesSection",
              id: "press-releases",
            },
          ],
        },
      }}
    />
  );
}

const query = graphql`
  query PressRoomQuery {
    template: contentfulPageTemplate(internalId: { eq: "Press room" }) {
      sections {
        ...PageSectionList_data
      }
    }
  }
`;

export { query };
export default PressRoom;
